
import { defineAsyncComponent, defineComponent } from 'vue';

/** Utilities */
import { HomeTypes } from '@/utils/mutation-types';

export default defineComponent({
  name: 'Dashboard',
  components: {
    MainView: defineAsyncComponent(
      () => import('@/components/home/MainView.vue')
    ),
    FinanciaTipsCarousel: defineAsyncComponent(
      () => import('@/components/home/FinancialTipsCarousel.vue')
    ),
  },
  data() {
    return {
      name: '...',
      auxData: {},
      dataLogin: {},
    };
  },
  computed: {
    client() {
      return this.$store.getters.getInfoClient;
    },

    timestamp() {
      const dateUpdate = this.$store.getters.getTimestamp;
      return dateUpdate ? new Date(dateUpdate).toLocaleDateString() : '';
    },
  },
  methods: {
    redirectToProfile(component: string) {
      this.$store.commit(HomeTypes.SET_COMPONENT, component);
    },
  },
  mounted() {
    this.$store.dispatch('getClient', {
      type: 'identificacion',
      search: 'sdfjaldf',
    });
  },
});
