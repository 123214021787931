import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-39fb2c26"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "content grid grid-rows-1 grid-cols-12 gap-2 p-2 h-full" }
const _hoisted_2 = { class: "col-span-8" }
const _hoisted_3 = { class: "col-span-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MainView = _resolveComponent("MainView")!
  const _component_FinanciaTipsCarousel = _resolveComponent("FinanciaTipsCarousel")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_MainView, { client: _ctx.client }, null, 8, ["client"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_FinanciaTipsCarousel)
    ])
  ]))
}